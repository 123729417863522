<template>
    <v-main>
        <v-container align-content-center>
            <div class="mb-5">
                <div class="text-h4">Update Floor Plan</div>
                This page can be used to update floor plans.
            </div>
            <v-select
                v-model="prev.fp"
                label="Choose existing floor plan"
                :items="floorPlans"
                prepend-icon="mdi-map"
                item-text="__str__"
                item-value="pk"
                @change="changeFloorPlan($event)"
                return-object
            />
            <template v-if="annotations.length">
                <v-select
                    v-model="prev.annotation"
                    prepend-icon="mdi-note"
                    label="Choose annotation"
                    :items="[...annotations, {__str__: '--- DO NOT COPY ANY ANNOTATIONS ---', pk: -1}]"
                    item-text="__str__"
                    item-value="pk"
                    return-object
                />
            </template>
            <v-file-input
                v-if="prev.annotation"
                v-model="add.fpFile"
                accept=".pdf"
                label="PDF file for new floor plan"
            />
            <div class="text-center">
                <v-btn
                    color="primary"
                    :disabled="!allowAdd"
                    @click="addFloorPlan"
                >Add Floor Plan</v-btn>
            </div>
        </v-container>
    </v-main>
</template>
<script>
import axios from 'axios';
import { ref, computed, onMounted } from '@vue/composition-api';

export default {
    name: 'NewFloorPlan',
    setup(_, { root }) {
        onMounted(() => {
            root.$store.dispatch('space/loadFloorPlans');
        });

        // const formValid = ref(true);
        const prev = ref({});
        const add = ref({});

        const floorPlans = computed(() => root.$store.getters['space/getFloorPlans']);
        const annotations = computed(() => root.$store.getters['space/getFloorPlanAnnotations']);
        const allowAdd = computed(() => prev.value.fp && prev.value.annotation && add.value.fpFile);

        const reset = () => {
            prev.value = {};
            add.value = {};
        };

        const changeFloorPlan = async (fp) => {
            await root.$store.dispatch('space/getFloorPlanAnnotations', fp.pk);
            root.$set(prev.value, 'annotation', annotations.value[0]);
        };

        const addFloorPlan = async () => {
            let formData = new FormData();

            formData.append('floorPlan', add.value.fpFile, add.value.fpFile.name);

            formData.append('data', JSON.stringify(prev.value));

            const url = `/space/add_update_floor_plan/`;

            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.created) {
                root.$store.commit('setSuccessSnack', 'Floor plan successfully created');
                reset();
                root.$store.dispatch('space/loadFloorPlans');
            } else {
                root.$store.commit('setErrorSnack', 'Error adding floor plan');
            }
        };
        
        return {
            prev,
            add,
            floorPlans,
            annotations,
            allowAdd,
            changeFloorPlan,
            addFloorPlan,
        };
    }
};
</script>